<script>
export default {
  name: 'IconInfo',
}
</script>

<template>
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 12 12">
    <path
      d="M6 0a6 6 0 1 0 .002 12 6 6 0 0 0-.003-12Zm0 2.212a.936.936 0 1 1 0 1.872.936.936 0 0 1 0-1.872Zm0 2.494a.9.9 0 0 1 .616.247.82.82 0 0 1 .25.592v3.403a.819.819 0 0 1-.254.597.894.894 0 0 1-1.232-.009.82.82 0 0 1-.246-.588V5.545a.82.82 0 0 1 .25-.593.894.894 0 0 1 .615-.246Z"
      fill="currentColor"
      fill-rule="evenodd"
    />
  </svg>
</template>
