import type { RouteLocationNormalized } from 'vue-router'

export function useUrl() {
  function isExternal(url: string) {
    const pattern = /^((http|https):\/\/)/
    if (pattern.test(url)) return true
    return false
  }

  function getSlugFilter(route: RouteLocationNormalized) {
    const path = route.params.slug || ''
    return path
      .split('/')
      .filter((part: string) => part !== '')
      .reduce((acc: any, part: string, index: number) => {
        const filter = { slug: { $eq: part } }
        if (index > 0) {
          filter.parent = acc
        }
        return filter
      }, {})
  }

  return {
    isExternal,
    getSlugFilter,
  }
}
