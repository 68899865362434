<script setup>
import { NuxtLink } from '#components'

const props = defineProps({
  path: {
    type: [String, Object],
    required: false,
    default: null,
  },
  text: {
    type: String,
    required: false,
    default: '',
  },
  theme: {
    type: String,
    required: false,
    default: 'primary',
    validator: (value) =>
      ['primary', 'secondary', 'tertiary', 'accent', 'transparent'].includes(
        value,
      ),
  },
  icon: {
    type: String,
    required: false,
    default: '',
  },
  noSubmit: {
    type: Boolean,
    required: false,
    default: false,
  },
  disabled: {
    type: Boolean,
    required: false,
    default: false,
  },
  info: {
    type: String,
    required: false,
    default: '',
  },
  thin: {
    type: Boolean,
    required: false,
    default: false,
  },
})
const slots = useSlots()
const { isExternal } = useUrl()

const hasIconRight = computed(() => {
  return !!slots['icon-right']
})

const component = computed(() => (props.path ? NuxtLink : 'button'))

const bindings = computed(() => {
  if (props.path) {
    return {
      to: props.path,
      text: props.text,
      target: isExternal(props.path) ? '_blank' : null,
    }
  } else {
    if (props.noSubmit) return { type: 'button', name: props.text }

    return {
      type: 'submit',
      name: props.text,
    }
  }
})
</script>

<template>
  <component
    :is="component"
    v-bind="bindings"
    class="group relative flex items-center justify-center rounded-xl px-6 py-4 pb-[18px] font-mono text-xs font-medium uppercase leading-none transition-all duration-100 xl:px-8"
    :class="[
      {
        'py-3': thin,
        'py-4 pb-[18px]': !thin,
      },
      {
        'bg-primary text-secondary': theme === 'primary',
      },
      {
        'hover:bg-secondary hover:text-primary':
          theme === 'primary' && !disabled,
      },
      {
        'bg-secondary text-stone-200': theme === 'secondary',
      },
      {
        'hover:bg-stone-200 hover:text-secondary':
          theme === 'secondary' && !disabled,
      },
      {
        'bg-tertiary text-secondary': theme === 'tertiary',
      },
      {
        'hover:bg-secondary hover:text-tertiary':
          theme === 'tertiary' && !disabled,
      },
      {
        'bg-accent text-secondary hover:bg-gray-800 hover:text-accent':
          theme === 'accent',
      },
      {
        'hover:bg-secondary hover:text-accent': theme === 'accent' && !disabled,
      },
      {
        'bg-transparent text-secondary ring-2 ring-inset ring-secondary':
          theme === 'transparent',
      },
      {
        'hover:bg-secondary hover:text-primary':
          theme === 'transparent' && !disabled,
      },
      { '!cursor-not-allowed !pointer-events-none !contrast-50': disabled },
    ]"
  >
    <slot name="content" />
    <span v-if="text">
      {{ text }}
    </span>
    <div v-if="hasIconRight" class="-mr-2">
      <slot name="icon-right" />
    </div>
    <InfoTippy
      v-if="info"
      :content="info"
      class="absolute right-3 top-1/2 -translate-y-1/2"
      :theme="theme"
      @click.stop
    />
  </component>
</template>
